// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";
import {
  saveConnectWiseApiKeys,
  getConnectWiseApiKeys,
} from "features/connectWise/connectWiseActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import SaveConnectWiseApiKeysFormErrorsBlock from "./SaveConnectWiseApiKeysFormErrorsBlock";
import SaveConnectWiseApiKeysFormFormikWrapper from "./SaveConnectWiseApiKeysFormFormikWrapper";
import MDTypography from "components/MDTypography";
import { textAlign } from "@mui/system";

// const objInitialValues = {
//   public_key: "",
//   private_key: "",
//   company_id: "",
// };
const SaveConnectWiseApiKeysFormJWTSchema = Yup.object().shape({
  url: Yup.string().required("Connectwise URL is Required"),
  public_key: Yup.string().required("Public key is Required"),
  private_key: Yup.string().required("Private key is Required"),
  company_id: Yup.string().required("Company id is Required"),
});
const SaveConnectWiseApiKeysForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingKeys, setIsLoadingKeys] = useState(true);
  const [objInitialValues, setObjInitialValues] = useState({
    url: "",
    public_key: "",
    private_key: "",
    company_id: "",
  });

  const [
    objSaveConnectWiseApiKeysFormErrors,
    setObjSaveConnectWiseApiKeysFormErrors,
  ] = useState({});
  const onSubmitForm = async (values) => {
    setObjSaveConnectWiseApiKeysFormErrors({});
    setIsLoading(true);
    dispatch(saveConnectWiseApiKeys(values))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg(
            "success",
            "Your ConnectWise Api Keys have been successfully saved."
          );
          // navigate("/clients");
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError);
        const objErrors = rejectedValueOrSerializedError.response.errors;
        if (typeof objErrors !== "undefined" && objErrors !== null) {
          setObjSaveConnectWiseApiKeysFormErrors(objErrors);
          showMsg("error", "Please correct the errors.");
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
        // handle result here
      });
  };

  useEffect(() => {
    fetchConnectWiseKeys();
  }, []);

  const fetchConnectWiseKeys = async () => {
    dispatch(getConnectWiseApiKeys(objInitialValues))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        let _objInitialValues = {
          url: response.data.url ?? "",
          public_key: response.data.key ?? "",
          private_key: response.data.secret ?? "",
          company_id: response.data.api_company_id ?? "",
        };
        setObjInitialValues(_objInitialValues);
        setIsLoadingKeys(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingKeys(false);
        // setIsLoading(false);
        // console.log(rejectedValueOrSerializedError);
        // const objErrors = rejectedValueOrSerializedError.response.errors;
        // if (typeof objErrors !== "undefined" && objErrors !== null) {
        //   setObjSaveConnectWiseApiKeysFormErrors(objErrors);
        //   showMsg("error", "Please correct the errors.");
        // } else {
        //   showMsg("error", "Something went wrong, please try again");
        // }
        // handle result here
      });
  };

  return (
    <>
      {isLoadingKeys === false ? (
        <Formik
          initialValues={objInitialValues}
          validationSchema={SaveConnectWiseApiKeysFormJWTSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"SaveConnectWiseApiKeysForm"} autoComplete="off">
              <Card sx={{ height: "100%" }}>
                <MDBox>
                  <SaveConnectWiseApiKeysFormFormikWrapper
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                  />

                  {Object.keys(objSaveConnectWiseApiKeysFormErrors).length >
                  0 ? (
                    <Grid
                      container
                      justifyContent="space-between"
                      pl={5}
                      pr={5}
                      pt={0}
                      pb={5}
                    >
                      <SaveConnectWiseApiKeysFormErrorsBlock
                        objSaveConnectWiseApiKeysFormErrors={
                          objSaveConnectWiseApiKeysFormErrors
                        }
                      />
                    </Grid>
                  ) : null}

                  <MDBox
                    sx={{ margin: "0 0 40px -40px" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <MDButton
                      component="a"
                      href="https://help-desk-migration.com/help/how-to-generate-connectwise-api-keys/"
                      target="_blank"
                      rel="noreferrer"
                      variant="gradient"
                      color="dark"
                      sx={{ padding: "12px 20px", margin: "0px 5px" }}
                    >
                      Learn How To Generate ConnectWise API Keys
                    </MDButton>
                    {isLoading === false ? (
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 20px", margin: "0px 5px" }}
                      >
                        Save
                      </MDButton>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
              {/* </MDBox> */}
            </Form>
          )}
        </Formik>
      ) : (
        <MDBox>
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
          <MDTypography sx={{ textAlign: "center" }}>Loading Keys</MDTypography>
        </MDBox>
      )}
    </>
  );
};

export default SaveConnectWiseApiKeysForm;
