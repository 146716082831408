import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

const connector = "ncentral";

export const saveApiKeys = createAsyncThunk(
  "company/saveApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/keys?id=${values.company_id}`,
        values.values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getConnectorApiKeys = createAsyncThunk(
  "company/ getConnectorApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/keys`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPreviousSyncInformation = createAsyncThunk(
  "company/getPreviousSyncInformation",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/get-previous-sync-information`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveSyncFrequencySettings = createAsyncThunk(
  "company/saveSyncSettings",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/save-sync-settings`,
        {
          selectedSyncOption: values.selectedValue,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getConnectorAssets = createAsyncThunk(
  "company/getConnectorAssets",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/fetch-assets`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const importConnectorAssets = createAsyncThunk(
  "company/importConnectorAssets",
  async (values, thunkApi) => {
    try {
      values = {
        id: values.id,
        totalAssetsFetched: values.totalAssetsFetched,
        assetData: values.modifiedAssetData,
      };
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/${connector}/import-assets`,
        values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
