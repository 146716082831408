import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import PageLayout from "views/containers/PageLayout";

import SignUpFunnelHeader from "../common/SignUpFunnelHeader";
import SignUpFunnelStepsHeader from "../common/SignUpFunnelStepsHeader";
import SignUpCreateNewOrganizationForm from "./components/SignUpCreateNewOrganizationForm";
import { getSteps } from "../common/StepsHelpers";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { objAllCountries } from "helpers/CountryHelpers/CountryHelpers";
import { useNavigate } from "react-router-dom";

const SignUpCreateNewOrganization = (props) => {
  const { objUser } = props;
  const activeStep = 2;
  const [isLoading, setIsLoading] = useState(true);
  const [objStates, setObjStates] = useState(objUsStates);
  const [objCountries, setObjCountries] = useState(objAllCountries);
  const steps = getSteps();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      typeof objUser.user !== "undefined" &&
      typeof objUser.user.email_verified_at !== "undefined"
    ) {
      const objAuthUser = objUser.user;

      // Only admin users can verify their email
      if (objAuthUser.role.name === "admin") {
        // If user already has a company, redirect to home
        if (
          objAuthUser.company !== null &&
          typeof objAuthUser.company !== "undefined"
        ) {
          navigate("/home");
        }
      } else {
        navigate("/home");
      }
    } else {
      navigate("/");
    }

    setIsLoading(false);
  }, []);

  return (
    <div>
      {isLoading ? null : (
        <PageLayout background="default">
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <SignUpFunnelHeader />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              width="100%"
            >
              <Grid item xs={12} lg={8}>
                <Card sx={{ height: "100%" }}>
                  <SignUpFunnelStepsHeader
                    steps={steps}
                    activeStep={activeStep}
                  />
                  <MDBox p={3}>
                    <MDBox>
                      <SignUpCreateNewOrganizationForm
                        objStates={objStates}
                        objCountries={objCountries}
                      />
                      <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      ></MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </PageLayout>
      )}
    </div>
  );
};

export default SignUpCreateNewOrganization;
