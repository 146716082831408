import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const getWisps = createAsyncThunk(
  "company/getWisps",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(`${BACKEND_API_URL}${VERSION_SERVER}/wisps`);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicies = createAsyncThunk(
  "company/getPolicies",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps/policies/all-published`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getApprovers = createAsyncThunk(
  "company/getApprovers",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps/contacts/approvers`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getUnapprovedWisps = createAsyncThunk(
  "company/getUnapprovedWisps",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps/unapproved`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const approveWisp = createAsyncThunk(
  "company/generateWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps/approve`,
        {
          wisp: values.wispObj,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const generateWisp = createAsyncThunk(
  "company/generateWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps`,
        {
          policiesToGenerateWispFrom: values.sections,
          overviewOscalSections: values.overviewOscalSections,
          selectedApprover: values.selectedApprover,
          name: values.wispName,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchWisp = createAsyncThunk(
  "company/fetchWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps/details/${values.id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const _deleteWisp = createAsyncThunk(
  "company/deleteWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps/${values.dataFromActionBtn.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getWispPDF = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/wisps/pdf/${values.wispId}`,
        { responseType: "blob", params: values.params }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
