import DefaultCell from "views/components/TableCells/DefaultCell";
const FifthWallStatsDataTableData = {
  columns: [
    {
      Header: "User",
      accessor: "user",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Client",
      accessor: "client",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Multi-Factor Authentication",
      accessor: "mfa",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Security Awareness Training & Testing",
      accessor: "security",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Separate Backups",
      accessor: "backup",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Endpoint / Managed Detection & Response",
      accessor: "detection",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Vulnerability Management",
      accessor: "vulnerability",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default FifthWallStatsDataTableData;
