/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  COMPANIES_SERVER,
  VERSION_SERVER,
} from "components/utils/constants/misc";

export const inviteUserToCompany = createAsyncThunk(
  "company/inviteUserToCompany",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/users/invite`,
        values
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const validateUserInvitationToCompany = createAsyncThunk(
  "company/validateUserInvitationToCompany",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/users/validate-invite`,
        values
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const acceptUserInvitationToCompany = createAsyncThunk(
  "company/acceptUserInvitationToCompany",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/users/accept-invite`,
        values
      );
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const companyUsersList = createAsyncThunk(
  "company/companyUsersList",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/users?per_page=${_per_page}&page=${_page}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearCompanyUsersList = createAsyncThunk(
  "client/clearCompanyUsersList",
  async (values, thunkApi) => {
    try {
      // const res = await axios.get(`${BACKEND_API_URL}${CLIENTS_SERVER}/list`);
      // return res.data;
      const res = {
        data: {
          status: "success",
          data: {
            current_page: 1,
            data: [],
            first_page_url: "",
            from: null,
            last_page: 1,
            last_page_url: "",
            next_page_url: null,
            path: "",
            per_page: 10,
            prev_page_url: null,
            to: null,
            total: 0,
          },
          isAuth: true,
          error: false,
        },
        status: 200,
      };
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateUserStatus = createAsyncThunk(
  "client/updateUserStatus",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/users/update-status/${objPostData.id}`,
        objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteCompanyUser = createAsyncThunk(
  "client/deleteCompanyUser",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/users/user/delete/${values.id}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteCompanyUserFromStore = createAsyncThunk(
  "client/deleteCompanyUserFromStore",
  async (values, thunkApi) => {
    try {
      return values;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchSubscriptionPlans = createAsyncThunk(
  "company/subscriptions",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/subscriptions`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchSubscriptionPlansForGuest = createAsyncThunk(
  "company/subscriptions-for-guest",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/subscriptions-for-guest`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const creteSubscriptionSession = createAsyncThunk(
  "company/creteSubscriptions",
  async (values, thunkApi) => {
    try {
      const { objUser, price_id, new_price, new_units, signup_for_free_trial } =
        values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/subscriptions`,
        {
          price_id: price_id,
          new_price: new_price,
          new_units: new_units,
          signup_for_free_trial: signup_for_free_trial,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**
 * @description Returns url with session parameters
 */
export const createCustomerPortalSession = createAsyncThunk(
  "company/createCustomerPortalSession",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/customer-portal`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchGamifycationStatsPolicies = createAsyncThunk(
  "company/fetchGamifycationStatsPolicies",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/gamifycation/policies`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchGamifycationStatsAssessments = createAsyncThunk(
  "company/fetchGamifycationStatsAssessments",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/gamifycation/assessments`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchGamifycationStatsAssets = createAsyncThunk(
  "company/fetchGamifycationStatsAssets",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/gamifycation/assets`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchGamifycationStatsInsurance = createAsyncThunk(
  "company/fetchGamifycationStatsInsurance",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/gamifycation/insurance`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const authGetCompany = createAsyncThunk(
  "company/authUpdateCompany",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}`
      );
      // return res.data;

      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const authUpdateCompany = createAsyncThunk(
  "company/authUpdateCompany",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}`,
        objPostData
      );
      // return res.data;

      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateAuthCompanyData = createAsyncThunk(
  "company/updateAuthCompanyData",
  async (values, thunkApi) => {
    try {
      // return res.data;
      const objUserData = {
        status: "success",
        user: values,
        isAuth: true,
        authorized: true,
        error: false,
      };
      return objUserData;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const convertToMSPUser = createAsyncThunk(
  "company/convertToMSPUser",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/users/convert-to-msp-user`,
        objPostData
      );
      // return res.data;

      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const convertToClientUser = createAsyncThunk(
  "company/convertToClientUser",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${objUser.user.company_id}/users/convert-to-client-user`,
        objPostData
      );
      // return res.data;

      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
