import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment, useEffect, useState } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import DoubleConfirmationDialog from "./DoubleConfirmationDialog";
import usePolicyPDFDownloader from "../CreatePolicyDocument/usePolicyPDFDownloader";
import PDFDownloadSettingsDialog from "views/pages/Dashboard/CompanyConfig/components/PDFDownloadSettingsDialog";

const PolicyActionBtnTr = (props) => {
  const { row } = props;

  const [openDoubleConfirmationDialog, setOpenDoubleConfirmationDialog] =
    useState(false);

  const showDoubleConfirmationDialog = (data) => {
    setOpenDoubleConfirmationDialog(true);
  };

  const onCloseDoubleConfirmationDialog = () => {
    setOpenDoubleConfirmationDialog(false);
  };

  /**pdf display settings */
  const [displayPdfSettingsValue, setDisplayPdfSettingValue] = useState(
    row.original?.user?.user?.company?.pdf_display_settings ?? null
  );
  const [openPdfSettingsDialog, setOpenPdfSettingsDialog] = useState(false);
  const onclosePdfSettingsDialog = () => {
    setOpenPdfSettingsDialog(false);
  };

  /**end pdf display settings */

  const onSubmitNewPolicyDialog = (policy) => {
    setOpenDoubleConfirmationDialog(false);
    window.location.reload();
  };

  const confirm = useConfirm();

  const { downloadPDF, isLoadingPDF } = usePolicyPDFDownloader();

  const btnOnClickDeletePolicy = (data) => {
    confirm({
      description: "Do you want to delete this policy?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        showDoubleConfirmationDialog(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  /**This method is called from the pdf display settings dialog box */
  const btnClickDownloadPolicyPDF = (dataObj) => {
    const objUser = dataObj.user;
    const params = {
      policy_id: dataObj.element.id,
      document_id: dataObj.element.latest_document.id,
      title: dataObj.element.title,
      pdf_display_settings: displayPdfSettingsValue,
    };

    downloadPDF(objUser, params);
  };

  return (
    <Fragment>
      <DoubleConfirmationDialog
        open={openDoubleConfirmationDialog}
        onClose={onCloseDoubleConfirmationDialog}
        onSubmit={onSubmitNewPolicyDialog}
        policy={row.original}
      />
      <PDFDownloadSettingsDialog
        openPdfSettingsDialog={openPdfSettingsDialog}
        onclosePdfSettingsDialog={onclosePdfSettingsDialog}
        displayPdfSettingsValue={displayPdfSettingsValue}
        setDisplayPdfSettingValue={setDisplayPdfSettingValue}
        startDownload={btnClickDownloadPolicyPDF}
        dataObj={row.original}
        isLoadingPDF={isLoadingPDF}
      />
      <MDBox>
        {/* download button */}

        <MDButton
          disabled={row.original.status !== "Published"}
          // onClick={() => btnClickDownloadPolicyPDF(row.original)}
          onClick={() => {
            setOpenPdfSettingsDialog(true);
          }}
        >
          <MDBox
            color="info"
            display="flex"
            alignItems="center"
            sx={{
              opacity: row.original.status !== "Published" ? 0.4 : 1,
            }}
          >
            <Icon sx={{ fontWeight: "bold" }}>download</Icon>
            <MDTypography
              variant="p"
              fontWeight="medium"
              color="text.primary"
              sx={{ lineHeight: 0 }}
            >
              {isLoadingPDF ? (
                // <LoadingSpinner
                //   subClass="text-center"
                //   color="success"
                //   size="lg"
                // />
                <></>
              ) : (
                ""
              )}
            </MDTypography>
          </MDBox>
        </MDButton>

        {/* delete button */}
        {(row.original?.obj_user?.user?.role?.name === "company_user" ||
          row.original?.obj_user?.user?.role?.name === "admin") && (
          <MDButton
            onClick={() => btnOnClickDeletePolicy(row.original)}
            variant="gradient"
            sx={{ marginLeft: "1rem" }}
          >
            <MDBox color="error" display="flex" alignItems="center">
              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
            </MDBox>
          </MDButton>
        )}
      </MDBox>
    </Fragment>
  );
};

export default PolicyActionBtnTr;
