import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as Yup from "yup";
import { useState } from "react";
import { Formik, Form } from "formik";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import AddNewCustomerFormFormikWrapper from "./AddNewCustomerFormFormikWrapper";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import AddNewCustomerFormErrorsBlock from "./AddNewCustomerFormErrorsBlock";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { objAllCountries } from "helpers/CountryHelpers/CountryHelpers";
import { storeAddCustomerClient } from "features/company/clientActions";
import { objAllIndustries } from "helpers/CountryHelpers/IndustryHelpers";
import InfoFormFormikWrapper from "./InfoFormFormikWrapper";
import { saveExtraInfo } from "features/company/insuranceActions";

const AddNewCustomerFormJWTSchema = Yup.object().shape({
  txtClientName: Yup.string().required("Client Name is required."),
  txtAddress1: Yup.string().required("Address 1 is required."),
  drpCountry: Yup.string().required("Country is required."),
  txtCity: Yup.string().required("City is required."),
  txtZip: Yup.string()
    .required("Zip code is required.")
    .min(4, "Please enter a valid zip code."),
});

const ExtraFormJWTSchema = Yup.object().shape({
  gtn_mfa: Yup.string().required("This field is required."),
  gtn_security_awareness: Yup.string().required("This field is required."),
  gtn_seperate_backup: Yup.string().required("This field is required."),
  gtn_endpoint_managed: Yup.string().required("This field is required."),
  gtn_vulnerability: Yup.string().required("This field is required."),
});

const AddNewCustomerForm = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [objStates, setObjStates] = useState(objUsStates);
  const [objCountries, setObjCountries] = useState(objAllCountries);
  const [isLoading, setIsLoading] = useState(false);
  const [objAddNewCustomerFormErrors, setObjAddNewCustomerFormErrors] =
    useState({});
  const [step, setStep] = useState(1);
  const [clientInfo, setClientInfo] = useState({});
  const [extraInfo, setExtraInfo] = useState({});
  const [objIndustries, setObjIndustries] = useState(objAllIndustries);

  const [objInitialExtraValues, setObjInitialExtraValues] = useState({
    gtn_mfa: "",
    gtn_mfa_vendor: "",
    gtn_mfa_tool: "",
    gtn_security_awareness: "",
    gtn_security_awareness_vendor: "",
    gtn_security_awareness_tool: "",
    gtn_seperate_backup: "",
    gtn_seperate_backup_vendor: "",
    gtn_seperate_backup_tool: "",
    gtn_endpoint_managed: "",
    gtn_endpoint_managed_vendor: "",
    gtn_endpoint_managed_tool: "",
    gtn_vulnerability: "",
    gtn_vulnerability_vendor: "",
    gtn_vulnerability_tool: "",
    flag_currentcyberpolicy: "",
    industry: "",
    naicscode: "",
  });

  const [objInitialValues, setObjInitialValues] = useState({
    txtClientName: "",
    txtAddress1: "",
    txtAddress2: "",
    txtCity: "",
    drpState: "",
    drpCountry: "United States of America",
    txtZip: "",
  });

  const onStepOneDone = (values) => {
    const objPostData = {
      txtClientName: values.txtClientName,
      txtAddress1: values.txtAddress1,
      txtAddress2: values.txtAddress2,
      txtCity: values.txtCity,
      drpState: values.drpState,
      drpCountry: values.drpCountry,
      txtZip: values.txtZip,
    };
    setClientInfo(objPostData);
    setStep(2);
  };

  const onStepTwoDone = async (values) => {
    let objPostData = {
      gtn_mfa: values.gtn_mfa,
      gtn_mfa_vendor: values.gtn_mfa_vendor,
      gtn_mfa_tool: values.gtn_mfa_tool,
      gtn_security_awareness: values.gtn_security_awareness,
      gtn_security_awareness_vendor: values.gtn_security_awareness_vendor,
      gtn_security_awareness_tool: values.gtn_security_awareness_tool,
      gtn_seperate_backup: values.gtn_seperate_backup,
      gtn_seperate_backup_vendor: values.gtn_seperate_backup_vendor,
      gtn_seperate_backup_tool: values.gtn_seperate_backup_tool,
      gtn_endpoint_managed: values.gtn_endpoint_managed,
      gtn_endpoint_managed_vendor: values.gtn_endpoint_managed_vendor,
      gtn_endpoint_managed_tool: values.gtn_endpoint_managed_tool,
      gtn_vulnerability: values.gtn_vulnerability,
      gtn_vulnerability_vendor: values.gtn_vulnerability_vendor,
      gtn_vulnerability_tool: values.gtn_vulnerability_tool,
      flag_currentcyberpolicy: values.flag_currentcyberpolicy,
      industry: values.industry,
      naicscode: values.naicscode,
    };
    setExtraInfo(objPostData);

    onSubmitForm(clientInfo, objPostData);
  };

  const saveFormExtraInfo = async (client_id, extraPostData) => {
    let objPostData = extraPostData;
    objPostData.client_id = client_id;
    dispatch(saveExtraInfo({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Your Client has been added successfully.");
          setTimeout(() => {
            window.location = `/clients`;
          }, 2000);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const onSubmitForm = async (values, extraPostData) => {
    if (values.drpCountry !== "United States of America") {
      values.drpState = "";
    } else if (
      values.drpCountry === "United States of America" &&
      values.drpState === ""
    ) {
      showMsg("error", "State is required.");
      return;
    }
    const objPostData = {
      name: values.txtClientName,
      address_1: values.txtAddress1,
      address_2: values.txtAddress2,
      city: values.txtCity,
      state: values.drpState,
      country: values.drpCountry,
      zip: values.txtZip,
      objUser: props.objUser,
    };
    setObjAddNewCustomerFormErrors({});
    setIsLoading(true);
    dispatch(storeAddCustomerClient({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 201) {
          saveFormExtraInfo(response.data.id, extraPostData);
        } else {
          setIsLoading(false);
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Please correct the errors 123.");
      });
  };
  return (
    <>
      {step == 1 && (
        <Formik
          initialValues={
            clientInfo.txtClientName
              ? { ...objInitialValues, ...clientInfo }
              : objInitialValues
          }
          validationSchema={AddNewCustomerFormJWTSchema}
          onSubmit={onStepOneDone}
          enableReinitialize
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"AddNewCustomerForm"} autoComplete="off">
              <Card sx={{ height: "100%" }}>
                <MDBox>
                  <AddNewCustomerFormFormikWrapper
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    objStates={objStates}
                    objCountries={objCountries}
                  />

                  {Object.keys(objAddNewCustomerFormErrors).length > 0 ? (
                    <Grid
                      container
                      justifyContent="space-between"
                      pl={5}
                      pr={5}
                      pt={0}
                      pb={5}
                    >
                      <AddNewCustomerFormErrorsBlock
                        objAddNewCustomerFormErrors={
                          objAddNewCustomerFormErrors
                        }
                      />
                    </Grid>
                  ) : null}

                  <MDBox
                    sx={{ margin: "0 0 40px -40px" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {isLoading === false ? (
                      <MDButton
                        type="submit"
                        color="success"
                        sx={{ padding: "12px 82px" }}
                      >
                        Next
                      </MDButton>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Form>
          )}
        </Formik>
      )}

      {step == 2 && (
        <Formik
          initialValues={{ ...objInitialExtraValues, ...extraInfo }}
          validationSchema={ExtraFormJWTSchema}
          onSubmit={onStepTwoDone}
          enableReinitialize
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"InfoForm"} autoComplete="off">
              <Card sx={{ height: "100%" }}>
                <MDBox>
                  <InfoFormFormikWrapper
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    objStates={objStates}
                    objIndustries={objIndustries}
                  />

                  <MDBox
                    sx={{ margin: "0 0 40px" }}
                    px={4}
                    width="100%"
                    display="flex"
                    justifyContent={isLoading ? "flex-end" : "space-between"}
                  >
                    {isLoading === false ? (
                      <>
                        <MDButton
                          type="button"
                          color="dark"
                          sx={{ padding: "12px 82px" }}
                          onClick={() => {
                            setStep(1);
                          }}
                        >
                          Back
                        </MDButton>
                        <MDButton
                          type="submit"
                          color="success"
                          sx={{ padding: "12px 82px" }}
                        >
                          Submit
                        </MDButton>
                      </>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddNewCustomerForm;
