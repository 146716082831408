export const objUsStates = [
  { label: "ALABAMA", id: "AL" },
  { label: "ALASKA", id: "AK" },
  { label: "AMERICAN SAMOA", id: "AS" },
  { label: "ARIZONA", id: "AZ" },
  { label: "ARKANSAS", id: "AR" },
  { label: "CALIFORNIA", id: "CA" },
  { label: "COLORADO", id: "CO" },
  { label: "CONNECTICUT", id: "CT" },
  { label: "DELAWARE", id: "DE" },
  { label: "DISTRICT OF COLUMBIA", id: "DC" },
  { label: "FEDERATED STATES OF MICRONESIA", id: "FM" },
  { label: "FLORIDA", id: "FL" },
  { label: "GEORGIA", id: "GA" },
  { label: "GUAM", id: "GU" },
  { label: "HAWAII", id: "HI" },
  { label: "IDAHO", id: "ID" },
  { label: "ILLINOIS", id: "IL" },
  { label: "INDIANA", id: "IN" },
  { label: "IOWA", id: "IA" },
  { label: "KANSAS", id: "KS" },
  { label: "KENTUCKY", id: "KY" },
  { label: "LOUISIANA", id: "LA" },
  { label: "MAINE", id: "ME" },
  { label: "MARSHALL ISLANDS", id: "MH" },
  { label: "MARYLAND", id: "MD" },
  { label: "MASSACHUSETTS", id: "MA" },
  { label: "MICHIGAN", id: "MI" },
  { label: "MINNESOTA", id: "MN" },
  { label: "MISSISSIPPI", id: "MS" },
  { label: "MISSOURI", id: "MO" },
  { label: "MONTANA", id: "MT" },
  { label: "NEBRASKA", id: "NE" },
  { label: "NEVADA", id: "NV" },
  { label: "NEW HAMPSHIRE", id: "NH" },
  { label: "NEW JERSEY", id: "NJ" },
  { label: "NEW MEXICO", id: "NM" },
  { label: "NEW YORK", id: "NY" },
  { label: "NORTH CAROLINA", id: "NC" },
  { label: "NORTH DAKOTA", id: "ND" },
  { label: "NORTHERN MARIANA ISLANDS", id: "MP" },
  { label: "OHIO", id: "OH" },
  { label: "OKLAHOMA", id: "OK" },
  { label: "OREGON", id: "OR" },
  { label: "PALAU", id: "PW" },
  { label: "PENNSYLVANIA", id: "PA" },
  { label: "PUERTO RICO", id: "PR" },
  { label: "RHODE ISLAND", id: "RI" },
  { label: "SOUTH CAROLINA", id: "SC" },
  { label: "SOUTH DAKOTA", id: "SD" },
  { label: "TENNESSEE", id: "TN" },
  { label: "TEXAS", id: "TX" },
  { label: "UTAH", id: "UT" },
  { label: "VERMONT", id: "VT" },
  { label: "VIRGIN ISLANDS", id: "VI" },
  { label: "VIRGINIA", id: "VA" },
  { label: "WASHINGTON", id: "WA" },
  { label: "WEST VIRGINIA", id: "WV" },
  { label: "WISCONSIN", id: "WI" },
  { label: "WYOMING", id: "WY" },
];
