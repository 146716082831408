import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const getSsps = createAsyncThunk(
  "company/getSsps",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(`${BACKEND_API_URL}${VERSION_SERVER}/ssps`);
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAssessments = createAsyncThunk(
  "company/getAssessments",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/ssps/assessments/most-recently-completed`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// export const getGlobalSectionsToBeIncludedInSsp = createAsyncThunk(
//   "company/getOscalSectionsToBeIncludedInSsp",
//   async (values, thunkApi) => {
//     try {
//       const res = await axios.get(
//         `${BACKEND_API_URL}${VERSION_SERVER}/ssps/assessments/ssp-overview-sections`
//       );
//       return res;
//     } catch (error) {
//       return thunkApi.rejectWithValue(error);
//     }
//   }
// );

export const generateSsp = createAsyncThunk(
  "company/generateSsp",
  async (values, thunkApi) => {
    // console.log(values);
    try {
      const res = await axios.post(`${BACKEND_API_URL}${VERSION_SERVER}/ssps`, {
        assessmentsToGenerateSspFrom: values.sections,
        name: values.sspName,
        introductionDescription: values.introductionDescription,
      });
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchSsp = createAsyncThunk(
  "company/fetchWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/ssps/details/${values.id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const _deleteSsp = createAsyncThunk(
  "company/deleteWisp",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/ssps/${values.dataFromActionBtn.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPDF = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/ssps/pdf/${values.sspId}`,
        { responseType: "blob", params: values.params }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
