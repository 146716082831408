/*
 * © 2024 ComplianceRisk.io Inc. doing business as Compliance Scorecard. All rights reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of ComplianceRisk.io Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are proprietary to ComplianceRisk.io Inc. and its suppliers and may be
 * covered by U.S. and Foreign Patents, patents in process, and are protected by trade secret or copyright law.
 *
 * Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained
 * from ComplianceRisk.io Inc.
 *
 * Compliance Scorecard is a leading SaaS platform specializing in compliance and risk management solutions for Managed Service Providers (MSPs),
 * Managed Security Service Providers (MSSPs), and virtual/fractional Chief Information Security Officers (vCISOs). Our cloud-hosted application
 * empowers small and medium-sized businesses to effectively understand and manage their compliance posture. Key features include compliance
 * monitoring, risk assessment tools, policy management, assessment management, full asset governance, integration capabilities, and detailed
 * dashboards and reporting. The platform aligns with SOC 2 Trust Service Criteria to ensure security, availability, processing integrity,
 * confidentiality, and privacy.
 *
 * For the full system description, please visit: https://SystemDescription.compliancescorecard.com
 */

// src/views/components/TopNavBar.js

import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import MDBox from "components/MDBox";
import colors from "assets/theme-dark/base/colors";
import Icon from "@mui/material/Icon";

import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import { getAllPendingReviewsForUser } from "features/user/notificationActions";
import { updateCurrentClientSelected } from "../../../features/company/companyUsersActions";

import GamificationSidebar from "views/components/GamificationSidebar";
import "./top-nav-styles.css";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { useMaterialUIController, setDarkMode } from "context";

const NotificationPopup = ({ open, onClose, pendingPolicyActionsCount }) => {
  const { dark } = colors;
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  const goToPendingReviewsPage = () => {
    window.location = "/pending-reviews";
  };

  const goToPendingAssetApprovalsPage = () => {
    window.location = "/assets/approvals";
  };

  const goToPendingWispApprovalsPage = () => {
    window.location = "/wisp/approvals";
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Notifications</DialogTitle>
      <DialogContent>
        {pendingPolicyActionsCount.pendingTotal == 0 ? (
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            You have no notifications at the moment
          </MDTypography>
        ) : (
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 500,
              marginTop: "1rem",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {pendingPolicyActionsCount.pendingReviewsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                  sx={{ backgroundColor: darkMode ? dark.main : "" }}
                >
                  <ListItemText
                    primary={`Pending Reviews (${pendingPolicyActionsCount.pendingReviewsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}

            {pendingPolicyActionsCount.pendingApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Approvals (${pendingPolicyActionsCount.pendingApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingLegalCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingReviewsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Legal Reviews (${pendingPolicyActionsCount.pendingLegalCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingAssetApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingAssetApprovalsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending Asset Approvals (${pendingPolicyActionsCount.pendingAssetApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
            {pendingPolicyActionsCount.pendingWispApprovalsCount > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={goToPendingWispApprovalsPage}
                  dense
                >
                  <ListItemText
                    primary={`Pending WISP Approvals (${pendingPolicyActionsCount.pendingWispApprovalsCount})`}
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              ""
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

const NotificationBell = ({ darkMode }) => {
  const [controller] = useMaterialUIController();
  const [open, setOpen] = useState(false);
  const [pendingPolicyActionsCount, setPendingPolicyActionsCount] = useState({
    pendingReviewsCount: 0,
    pendingApprovalsCount: 0,
    pendingLegalCount: 0,
    pendingWispApprovalsCount: 0,
    pendingTotal: 0,
  });
  const dispatch = useDispatch();

  const showNotificationsDialog = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchPendingReviewsForUser();
  }, []);

  const fetchPendingReviewsForUser = () => {
    const _per_page = 100;
    const _page = 1;
    dispatch(getAllPendingReviewsForUser({ _per_page, _page }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setPendingPolicyActionsCount({
          pendingReviewsCount: response.data.pendingReviewsCount,
          pendingApprovalsCount: response.data.pendingApprovalsCount,
          pendingAssetApprovalsCount: response.data.pendingAssetApprovalsCount,
          pendingLegalCount: response.data.pendingLegalCount,
          pendingWispApprovalsCount: response.data.pendingWispApprovalsCount,
          pendingTotal:
            response.data.pendingReviewsCount +
            response.data.pendingApprovalsCount +
            response.data.pendingAssetApprovalsCount +
            response.data.pendingLegalCount +
            response.data.pendingWispApprovalsCount,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  return (
    <>
      <NotificationPopup
        open={open}
        onClose={onClose}
        pendingPolicyActionsCount={pendingPolicyActionsCount}
      />
      <IconButton color="inherit" onClick={showNotificationsDialog}>
        <Badge
          badgeContent={pendingPolicyActionsCount.pendingTotal}
          color="error"
        >
          <NotificationsIcon color={darkMode ? "white" : ""} />
        </Badge>
      </IconButton>
    </>
  );
};

const DarkModeToggle = (objUser) => {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const handleDarkMode = () => {
    setDarkMode(dispatch, !darkMode);
    localStorage.setItem("darkMode", !darkMode);
  };

  return (
    <>
      {darkMode ? (
        <IconButton onClick={handleDarkMode}>
          <LightModeIcon sx={{ color: "#fff" }} />
        </IconButton>
      ) : (
        <IconButton onClick={handleDarkMode}>
          <DarkModeIcon sx={{ color: "#fff" }} />
        </IconButton>
      )}
    </>
  );
};

export default function TopNavBar({ topNavRoutes, miniSidenav }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { objUser } = useSelector((state) => ({
    objUser: state.auth.user.objUser,
  }));

  const [currentClient, setCurrentClient] = useState({
    id: "",
    name: "",
    status: "",
    city: "",
    state: "",
    status_label: "",
  });
  const [listOfClients, setListOfClients] = useState([]);
  const [viewDropDown, setViewDropDown] = useState("");
  const [switcherLoading, setswitcherLoading] = useState(false);
  const dispatchCurrentClientSelectedUpdate = useDispatch();

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [openMenuKey, setOpenMenuKey] = useState(null);

  const handleMenuClick = (event, key) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuKey(key);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuKey(null);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (objUser !== undefined && Object.keys(objUser).length > 0) {
      if (objUser.authorized && objUser.user.role.name !== "peer_group_admin") {
        setListOfClients(objUser.user.list_of_clients);
        if (objUser.user.role.name == "client_user") {
          setCurrentClient(objUser.user.list_of_clients);
          setViewDropDown(false);
        } else {
          let current_client = objUser.user?.list_of_clients?.find((item) => {
            return item.id === objUser.user.current_client_id;
          });
          setCurrentClient(current_client);
          setViewDropDown(true);
        }
      }
    }
  }, []);

  const backgroundValue = darkMode
    ? "#1f283e"
    : "linear-gradient(#032c4c, #053960)";
  const dropshadowValue = darkMode ? "0 5px 16px #0c101d" : "0 5px 10px #555";
  const dropdownMenuValue = darkMode ? "#ffffff" : "#0c314e";

  function handleClientSwitcherChange(event, value) {
    setCurrentClient(value);
    setswitcherLoading(true);
    dispatchCurrentClientSelectedUpdate(updateCurrentClientSelected({ value }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        window.location = "/home";
      })
      .catch((rejectedValueOrSerializedError) => {
        setswitcherLoading(false);
        console.log(rejectedValueOrSerializedError);
      });
  }

  return (
    <>
      <GamificationSidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <AppBar
        className="top-header-menu"
        position="sticky"
        sx={{
          background: backgroundValue,
          color: "#fff",
          height: "75px",
          boxShadow: dropshadowValue,
        }}
      >
        <Toolbar
          sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
            p: 3,
            position: "relative",
            height: "75px",

            [breakpoints.up("xl")]: {
              marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
              transition: transitions.create(["margin-left", "margin-right"], {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.standard,
              }),
            },
          })}
        >
          {/* Selected client switcher */}

          <MDBox mt={1.5} mb={1.5} pb={1} px={4} className="auto_c">
            <div
              className="switcher-overlay"
              style={{ display: `${switcherLoading ? "flex" : "none"}` }}
            >
              <LoadingSpinner></LoadingSpinner>
            </div>
            {viewDropDown == true ? (
              <Autocomplete
                disableClearable
                value={currentClient}
                options={listOfClients}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="Selected Company"
                    fullWidth
                    InputLabelProps={{
                      style: { color: "white", fontSize: "14px" }, // Apply custom styles to label
                    }}
                    InputProps={{
                      style: {
                        color: "white",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        marginTop: "10px",
                      },
                      ...params.InputProps,
                    }}
                  />
                )}
                onChange={handleClientSwitcherChange}
              />
            ) : (
              <MDTypography variant="p" sx={{ color: "#fff" }}>
                {currentClient.name}
              </MDTypography>
            )}
          </MDBox>
          <MDBox color="inherit" sx={{ marginLeft: "auto", display: "flex" }}>
            <MDBox px={3} sx={{ color: "#fff", display: "flex" }}>
              {/* Rendering topNavRoutes */}
              {topNavRoutes.map((route) => {
                // Don't render if user doesn't have access
                if (
                  route?.clientPermissionRequired &&
                  !route?.clientPermissionRequired.some(
                    (item) => objUser.user.client_feature_access.includes(item)
                  ) &&
                  objUser.user.role.name !== "admin" &&
                  objUser.user.role.name !== "company_user"
                ) {
                  return false;
                }
                if (
                  route.canBeAccessedBy &&
                  !route.canBeAccessedBy?.includes(objUser.user.role.name)
                ) {
                  return null;
                }
                if (
                  route.cannotBeAccessedBy &&
                  route.cannotBeAccessedBy?.includes(objUser.user.role.name)
                ) {
                  return null;
                }

                if (route.collapse) {
                  return (
                    <>
                      <Typography
                        key={route.key}
                        variant="body1"
                        px={2}
                        sx={{
                          fontSize: "14px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={(event) => handleMenuClick(event, route.key)}
                      >
                        {route.name}
                        <Icon sx={{ marginLeft: 1 }}>keyboard_arrow_down</Icon>
                      </Typography>

                      <Menu
                        anchorEl={anchorEl}
                        open={openMenuKey === route.key}
                        onClose={handleMenuClose}
                      >
                        {route.collapse.map((collapseItem) => {
                          if (
                            collapseItem?.component?.props
                              ?.clientPermissionRequired &&
                            !collapseItem?.component?.props?.clientPermissionRequired.some(
                              (item) =>
                                objUser.user.client_feature_access.includes(
                                  item
                                )
                            ) &&
                            // !objUser.user.client_feature_access.includes(
                            //   collapseItem?.component?.props
                            //     ?.clientPermissionRequired[0]
                            // )
                            objUser.user.role.name !== "admin" &&
                            objUser.user.role.name !== "company_user"
                          ) {
                            return false;
                          }

                          if (
                            collapseItem.mustBeCompanyAdminOrCompanyUser &&
                            !(
                              objUser.user.role.name === "admin" ||
                              objUser.user.role.name === "company_user"
                            )
                          ) {
                            return null;
                          }
                          if (
                            collapseItem.mustBeClientUser &&
                            !(objUser.user.role.name === "client_user")
                          ) {
                            return null;
                          }

                          return (
                            <Link
                              key={collapseItem.key}
                              to={collapseItem.route}
                              style={{
                                color: dropdownMenuValue,
                                textDecoration: "none",
                                fontSize: "15px",
                              }}
                            >
                              <MenuItem
                                sx={{ borderRadius: "0", padding: "10px" }}
                              >
                                {collapseItem.name}
                              </MenuItem>
                            </Link>
                          );
                        })}
                      </Menu>
                    </>
                  );
                }

                // Handle non-collapsible routes
                if (
                  route.mustBeCompanyAdminOrCompanyUser &&
                  !(
                    objUser.user.role.name === "admin" ||
                    objUser.user.role.name === "company_user"
                  )
                ) {
                  return null;
                }
                if (
                  route.mustBeClientUser &&
                  !(objUser.user.role.name === "client_user")
                ) {
                  return null;
                }

                return (
                  <Typography
                    key={route.key}
                    variant="body1"
                    px={2}
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      to={route.route}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {route.name}
                    </Link>
                  </Typography>
                );
              })}
            </MDBox>

            <Tooltip title="View Notifications">
              <NotificationBell darkMode={darkMode} />
            </Tooltip>
            <Tooltip title="Toggle Dark Mode">
              <DarkModeToggle objUser={objUser} />
            </Tooltip>
            {(objUser.user.role.name === "admin" ||
              objUser.user.role.name === "company_user") && (
              <Tooltip title="View LeaderBoard">
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={toggleSidebar}
                >
                  <Icon fontSize="medium" sx={{ color: "#fff" }}>
                    bar_chart
                  </Icon>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Logout">
              <Typography
                key="logout"
                variant="body1"
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link
                  to="/logout"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    position: "relative",
                    top: "3px",
                  }}
                >
                  <Icon fontSize="medium">logout</Icon>
                </Link>
              </Typography>
            </Tooltip>
          </MDBox>
        </Toolbar>
      </AppBar>
    </>
  );
}
