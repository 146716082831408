// react-router-dom components
import { useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import SignUpCreateNewOrganizationFormikWrapper from "./SignUpCreateNewOrganizationFormikWrapper";
import { storeCompany } from "features/user/userSignUpFunnelActions";
import { auth } from "features/user/userActions";
const objInitialValues = {
  txtCompanyName: "",
  txtAddress1: "",
  txtAddress2: "",
  drpCountry: "United States of America",
  txtCity: "",
  drpState: "",
  txtZip: "",
};
const FormJWTSchema = Yup.object().shape({
  txtCompanyName: Yup.string().required("Company Name is required."),
  txtAddress1: Yup.string().required("Address 1 is required."),
  drpCountry: Yup.string().required("Country is required."),
  txtCity: Yup.string().required("City is required."),
  // drpState: Yup.string().required("State is required."),
  txtZip: Yup.string()
    .required("Zip code is required.")
    .min(4, "Please enter a valid zip code."),
});
const SignUpCreateNewOrganizationForm = (props) => {
  const { objStates, objCountries } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [objCreateNewOrganizationErrors, setObjCreateNewOrganizationErrors] =
    useState({});

  const handleBack = () => {
    navigate("/setup-account/step-one");
  };

  const onSubmitForm = async (values) => {
    if (values.drpCountry !== "United States of America") {
      values.drpState = "";
    } else if (
      values.drpCountry === "United States of America" &&
      values.drpState === ""
    ) {
      showMsg("error", "State is required.");
      return;
    }

    const objPostData = {
      name: values.txtCompanyName,
      address_1: values.txtAddress1,
      address_2: values.txtAddress2,
      country: values.drpCountry,
      city: values.txtCity,
      state: values.drpState,
      zip: values.txtZip,
    };
    setObjCreateNewOrganizationErrors({});
    setIsLoading(true);
    dispatch(storeCompany(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          setIsLoading(true);
          showMsg("success", "Your company has been successfully created.");
          dispatch(auth()).then((originalPromiseResultAuth) => {
            setIsLoading(false);
            // navigate("/sign-up/pick-a-plan");
            navigate("/billing");
          });
        } else {
          showMsg(
            "error",
            "Something went wrong on company creation. Please try again!"
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        // showMsg("error", "Please correct the errors.");
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        if (objErrors !== undefined) {
          setObjCreateNewOrganizationErrors(objErrors);
        }
        //else {
        //   showMsg("error", "Something went wrong, please try again");
        // }
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Something went wrong");
        }
      });
  };
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={FormJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ values, errors, touched, isSubmitting, setFieldValue }) => (
        <Form id={"new-company-form"} autoComplete="off">
          <SignUpCreateNewOrganizationFormikWrapper
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            handleBack={handleBack}
            objCreateNewOrganizationErrors={objCreateNewOrganizationErrors}
            isLoading={isLoading}
            objStates={objStates}
            objCountries={objCountries}
          />

          {/* <MDBox mt={4} mb={1}>
            {isLoading === false ? (
              <MDButton
                type="submit"
                variant="gradient"
                color="success"
                size="large"
                fullWidth
                // onClick={onSubmitForm}
              >
                sign in
              </MDButton>
            ) : (
              <LoadingSpinner subClass="text-center" color="success" size="lg" />
            )}
          </MDBox> */}
        </Form>
      )}
    </Formik>
  );
};

export default SignUpCreateNewOrganizationForm;
