// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import * as Yup from "yup";

import { useEffect, useState } from "react";

// formik components
// import { Form, Formik } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// Authentication layout components
// Other authentication methods components
// Images
import { unwrapResult } from "@reduxjs/toolkit";
import { getWisps, _deleteWisp } from "features/wisps/wispActions";
// import { updateCurrentClientSelected } from "features/company/companyUsersActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardBreadcrumbs from "views/components/Navbars/DashboardBreadcrumbs";
import { standardDateFormat } from "utils/general";

import wispImageLogo from "assets/images/wisp-preview.png";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import TableRow from "@mui/material/TableRow";

import borders from "assets/theme/base/borders";

import {
  Alert,
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import DashboardActionButton from "views/components/DashboardActionButton";

import WispsManagementTable from "./components/WispsTable/ManagementTable";
import dataTableData from "./components/WispsTable/DataTableData";

import InfoIcon from "@mui/icons-material/Info";

const Index = (props) => {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const { objUser } = props;
  // const { id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const dispatch = useDispatch();
  const confirm = useConfirm();

  /**Wisp table */
  const [isDeletingWisp, setIsDeletingWisp] = useState(false);
  const [isLoadingWisps, setIsLoadingWisps] = useState(false);
  const [objWispsTableData, setObjWispsTableData] = useState(dataTableData);
  const [oscalSectionsToInclude, setOscalSectionsToInclude] = useState("");
  const [totalWispsCount, setTotalWispsCount] = useState(0);
  const [perPageWisps, setPerPageWisps] = useState(10);
  const [pageWisp, setPageWisp] = useState(1);

  useEffect(() => {
    // if (
    //   objUser.user.role.name != "admin" &&
    //   objUser.user.role.name != "company_user"
    // ) {
    //   navigate(`/home`, {
    //     state: { routeDictionary },
    //   });
    // }
    loadMoreWisps();
  }, []);

  const loadMoreWisps = (_per_page, _page) => {
    setIsLoadingWisps(true);
    dispatch(getWisps({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingWisps(false);
        setOscalSectionsToInclude(originalPromiseResult.data.overview_sections);
        populateWispTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingWisps(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const populateWispTableData = (response) => {
    const objRows = [];
    const objList = response.data.wisps;

    for (let index = 0; index < objList.length; index++) {
      const element = objList[index];
      const objTr = {};
      objTr.id = element.id;
      objTr.name = element.name;
      objTr.status = element.status;
      objTr.created_by = element.creator.name;
      objTr.created_at = standardDateFormat(element.created_at);
      objRows.push(objTr);
    }
    setTotalWispsCount(response.data.total);
    setObjWispsTableData({
      rows: objRows,
    });
  };

  const deleteWisp = async (dataFromActionBtn) => {
    setIsDeletingWisp(true);
    dispatch(_deleteWisp({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingWisp(false);
        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "WISP deleted");
          populateWispTableData(response);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingWisp(false);
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  const navigateToCreateNewWisp = () => {
    navigate(`/wisp/new`);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardBreadcrumbs />
        <Grid container width="100%" spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox
              display="flex"
              gap=".6rem"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mb={2}
            >
              <MDTypography variant="h4" sx={{ paddingTop: "1.2rem" }}>
                Written Information Security Policy
              </MDTypography>
              <DashboardActionButton
                action={navigateToCreateNewWisp}
                btnText="Create New WISP"
                btnIcon="add"
                textColor="white"
                bgColor="success"
              />
            </MDBox>
            <MDBox>
              <Alert icon={<InfoIcon fontSize="inherit" />} severity="success">
                This allows you to create WISPs for published documents. If your
                policy is using the OSCAL format, we will pull in the following
                sections: {oscalSectionsToInclude}, as well as a link to the
                Knowledge Base document. If you aren't using OSCAL format, we
                will only display a link to the Knowledge Base document.
              </Alert>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoadingWisps ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {totalWispsCount === 0 ? (
                    <>
                      <MDBox mb={2}>
                        <img src={wispImageLogo} width="90px" alt="" />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          You haven't added any WISPs yet
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          type="submit"
                          // variant="gradient"
                          color="success"
                          onClick={() => navigateToCreateNewWisp()}
                          sx={{ padding: "12px 20px", margin: "0px 5px" }}
                        >
                          Create New WISP
                        </MDButton>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <WispsManagementTable
                        table={objWispsTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalWispsCount}
                        loadMoreObj={loadMoreWisps}
                        perPage={perPageWisps}
                        setPerPage={setPerPageWisps}
                        _page={pageWisp}
                        setPage={setPageWisp}
                        isDeletingWisp={isDeletingWisp}
                        setObjWispsTableData={setObjWispsTableData}
                        deleteObj={deleteWisp}
                        objUser={objUser}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default Index;
