import { Fragment, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

/**
 * Params needed:
 * 1. ObjUser
 * 2. State variable from outer state to store the current PDF Settings
 * 3. Radio stuff to update the settings accordingly
 *
 */

function PDFDownloadSettingsDialog({
  openPdfSettingsDialog,
  onclosePdfSettingsDialog,
  displayPdfSettingsValue,
  setDisplayPdfSettingValue,
  startDownload,
  dataObj,
  isLoadingPDF,
}) {
  const handleDisplaySettingsRadioChange = (event) => {
    if (event.target && event.target.name) {
      setDisplayPdfSettingValue((prev) => {
        return { ...prev, [event.target.name]: event.target.value };
      });
    }
  };

  return (
    <Dialog
      open={openPdfSettingsDialog}
      onClose={onclosePdfSettingsDialog}
      fullWidth={true}
    >
      <DialogTitle sx={{ pb: 0, pl: 2.5 }}>
        Document Download Settings
      </DialogTitle>
      <DialogContent>
        {/* cover page */}
        <RadioGroup
          name="coverPage"
          value={displayPdfSettingsValue?.coverPage ?? "no"}
          onChange={handleDisplaySettingsRadioChange}
          fullWidth
          sx={{
            marginTop: "1rem",
          }}
        >
          <Grid spacing={2} sx={{ ml: 0, width: "100%" }} container>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="yes"
                control={<Radio />}
                sx={{ padding: "0" }}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        coverPage: "yes",
                      }))
                    }
                  >
                    Enable Cover Page
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="no"
                control={<Radio />}
                sx={{ padding: "0" }}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        coverPage: "no",
                      }))
                    }
                  >
                    Disable Cover Page
                  </div>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>

        {dataObj?.element?.policy_type && (
          <>
            {/* revision history */}
            <RadioGroup
              name="exportType"
              value={displayPdfSettingsValue?.revisionHistory ?? "no"}
              onChange={handleDisplaySettingsRadioChange}
              sx={{ marginTop: "1rem" }}
            >
              <Grid spacing={2} container sx={{ ml: 0, width: "100%" }}>
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    className="emailPreferenceRadioDiv"
                    value="yes"
                    control={<Radio />}
                    sx={{ padding: "0" }}
                    label={
                      <div
                        onClick={() =>
                          setDisplayPdfSettingValue((prev) => ({
                            ...prev,
                            revisionHistory: "yes",
                          }))
                        }
                      >
                        Include Revision History
                      </div>
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <FormControlLabel
                    className="emailPreferenceRadioDiv"
                    value="no"
                    control={<Radio />}
                    sx={{ padding: "0" }}
                    label={
                      <div
                        onClick={() =>
                          setDisplayPdfSettingValue((prev) => ({
                            ...prev,
                            revisionHistory: "no",
                          }))
                        }
                      >
                        Exclude Revision History
                      </div>
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </>
        )}

        {dataObj?.element?.policy_type !== "asset" && (
          <Fragment>
            <>
              {" "}
              {/* Landscape or portrait mode */}
              <RadioGroup
                name="pageOrientation"
                value={displayPdfSettingsValue?.pageOrientation ?? "portrait"}
                onChange={handleDisplaySettingsRadioChange}
                sx={{ marginTop: "1rem" }}
              >
                <Grid spacing={2} container sx={{ ml: 0, width: "100%" }}>
                  <Grid item xs={12} lg={6}>
                    <FormControlLabel
                      className="emailPreferenceRadioDiv"
                      value="portrait"
                      control={<Radio />}
                      sx={{ padding: "0" }}
                      label={
                        <div
                          onClick={() =>
                            setDisplayPdfSettingValue((prev) => ({
                              ...prev,
                              pageOrientation: "portrait",
                            }))
                          }
                        >
                          Portrait format
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControlLabel
                      className="emailPreferenceRadioDiv"
                      value="landscape"
                      control={<Radio />}
                      sx={{ padding: "0" }}
                      label={
                        <div
                          onClick={() =>
                            setDisplayPdfSettingValue((prev) => ({
                              ...prev,
                              pageOrientation: "landscape",
                            }))
                          }
                        >
                          Landscape format
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </>
          </Fragment>
        )}

        {/* Word file or pdf file selection */}
        <RadioGroup
          name="exportType"
          value={displayPdfSettingsValue?.exportType ?? "pdf"}
          onChange={handleDisplaySettingsRadioChange}
          sx={{ marginTop: "1rem" }}
        >
          <Grid spacing={2} container sx={{ ml: 0, width: "100%" }}>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="pdf"
                control={<Radio />}
                sx={{ padding: "0" }}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        exportType: "pdf",
                      }))
                    }
                  >
                    PDF
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="word"
                control={<Radio />}
                sx={{ padding: "0" }}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        exportType: "word",
                      }))
                    }
                  >
                    MS Word Document
                  </div>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>

        {/* index */}
        <RadioGroup
          name="indexPage"
          value={displayPdfSettingsValue?.indexPage ?? "no"}
          onChange={handleDisplaySettingsRadioChange}
          sx={{ marginTop: "1rem", display: "none" }}
        >
          <Grid spacing={2} container width="100%">
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="yes"
                control={<Radio />}
                sx={{ padding: "0" }}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        indexPage: "yes",
                      }))
                    }
                  >
                    Enable Index
                  </div>
                }
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControlLabel
                className="emailPreferenceRadioDiv"
                value="no"
                control={<Radio />}
                sx={{ padding: "0" }}
                label={
                  <div
                    onClick={() =>
                      setDisplayPdfSettingValue((prev) => ({
                        ...prev,
                        indexPage: "no",
                      }))
                    }
                  >
                    Disable Index
                  </div>
                }
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        {isLoadingPDF ? (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        ) : (
          <MDButton
            onClick={() => startDownload(dataObj)}
            type="submit"
            color="success"
            sx={{ padding: "12px 12px" }}
          >
            Start Export
          </MDButton>
        )}
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onclosePdfSettingsDialog}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default PDFDownloadSettingsDialog;
