import { Fragment, useEffect, useMemo, useState } from "react";
// react-table components
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React examples
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";

import DefaultCell from "views/components/TableCells/DefaultCell";
import RiskRegisterItemActionBtnTr from "./RiskRegisterItemActionBtnTr";
import AddToRiskMatrixBtn from "./AddToRiskMatrixBtn";
import RiskRegisterItemDeleteBtnTr from "./RiskRegisterItemDeleteBtnTr";
import { Checkbox, IconButton, Pagination, Tooltip } from "@mui/material";
import ImageToolTip from "./ImageToolTip";
import Legend from "assets/images/risk-register-actions.png";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useMaterialUIController } from "context";

function RiskRegisterTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  totalCount,
  takeTheAssessment,
  listItems,
  objUser,
  loadMoreItems,
  listId,
  perPage,
  _page,
  setPage,
  findingsToAdd,
  setFindingsToAdd,
}) {
  const [data, setData] = useState(table.rows);
  const [pageIndex, setPageIndex] = useState(_page - 1);
  const [pageSize, setPageSize] = useState(perPage);
  const [globalFilter, setGlobalFilter] = useState("");
  const [entries, setEntries] = useState(["10", "25", "50", "100"]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  function assignColor(condition) {
    switch (condition) {
      case "Needs Attention":
        return "#F5E0CB";
      case "Acceptable Risk":
        return "#BADAF5";
      case "At Risk":
        return "#FCD5D4";
      case "Satisfactory":
        return "#B4EFBA";
      default:
        return "#d3d3d3";
    }
  }

  const objColumns = listItems
    ? [
        {
          Header: "Assessment Event",
          accessor: "eventName",
          Cell: ({ value, column, row }) => (
            <DefaultCell value={value} column={column} row={row} />
          ),
        },
        {
          Header: "Item Name",
          accessor: "itemName",
          Cell: ({ value, column, row }) => (
            <Tooltip title={value} placement="top-end">
              <DefaultCell
                value={value.length > 60 ? value.slice(0, 60) + "..." : value}
              />
              <></>
            </Tooltip>
          ),
        },
        {
          Header: "Risk Level",
          accessor: "riskLevel",
          Cell: ({ value, column, row }) => (
            <DefaultCell
              value={value ?? ""}
              column={column}
              row={row}
              sx={{
                backgroundColor: assignColor(value),
                borderRadius: "8px",
                padding: "10px",
              }}
            />
          ),
        },
        {
          Header: () => (
            <MDBox
              sx={{ width: "120px", color: darkMode ? "white" : "#7b809a" }}
            >
              <Tooltip
                title={
                  "Visit Assessment Reports page and add item to Risk Matrix"
                }
                placement="right-end"
              >
                <IconButton sx={{ paddingTop: "2px", paddingLeft: "0px" }}>
                  <HelpOutlineIcon color="icon" fontSize="small" />
                </IconButton>
              </Tooltip>
              Priority Level
            </MDBox>
          ),
          accessor: "priorityLevel",
          Cell: ({ value, column, row }) =>
            row.original.priorityLevel ? (
              <DefaultCell value={value ?? ""} column={column} row={row} />
            ) : (
              <AddToRiskMatrixBtn objUser={objUser} row={row} />
            ),
        },
        {
          Header: () => (
            <>
              <ImageToolTip info={<img src={Legend} />} />
              Decided Action
            </>
          ),
          accessor: "action",
          Cell: ({ value, column, row }) => (
            <RiskRegisterItemActionBtnTr
              value={value ?? ""}
              column={column}
              row={row}
              objUser={objUser}
              loadMoreItems={loadMoreItems}
              listId={listId}
            />
          ),
        },
        ...(objUser.user.role.name === "company_user" ||
        objUser.user.role.name === "admin"
          ? [
              {
                Header: "Action",
                Cell: ({ value, column, row }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <RiskRegisterItemDeleteBtnTr
                      value={value}
                      column={column}
                      row={row}
                      objUser={objUser}
                    />
                  </div>
                ),
              },
            ]
          : []),
      ]
    : [
        {
          Header: "Assessment Event",
          accessor: "eventName",
          Cell: ({ value, column, row }) => (
            <DefaultCell value={value} column={column} row={row} />
          ),
        },
        {
          Header: "Item Name",
          accessor: "itemName",
          Cell: ({ value, column, row }) => (
            <Tooltip title={value} placement="top-end">
              <DefaultCell
                value={value.length > 60 ? value.slice(0, 60) + "..." : value}
              />
              <></>
            </Tooltip>
          ),
        },
        {
          Header: "Risk Level",
          accessor: "riskLevel",
          Cell: ({ value, column, row }) => (
            <DefaultCell
              value={value ?? ""}
              column={column}
              row={row}
              sx={{
                backgroundColor: assignColor(value),
                borderRadius: "8px",
                padding: "10px",
              }}
            />
          ),
        },
        {
          Header: () => (
            <MDBox
              sx={{ width: "120px", color: darkMode ? "white" : "#7b809a" }}
            >
              <Tooltip
                title={
                  "Visit Assessment Reports page and add item to Risk Matrix"
                }
                placement="right-end"
              >
                <IconButton sx={{ paddingTop: "2px", paddingLeft: "0px" }}>
                  <HelpOutlineIcon color="icon" fontSize="small" />
                </IconButton>
              </Tooltip>
              Priority Level
            </MDBox>
          ),
          accessor: "priorityLevel",
          Cell: ({ value, column, row }) =>
            row.original.priorityLevel ? (
              <DefaultCell value={value ?? ""} column={column} row={row} />
            ) : (
              <AddToRiskMatrixBtn objUser={objUser} row={row} />
            ),
        },
        ...(objUser.user.role.name == "company_user" ||
        objUser.user.role.name == "admin"
          ? [
              {
                Header: "Action",
                Cell: ({ value, column, row }) => (
                  <RiskRegisterItemDeleteBtnTr
                    value={value}
                    column={column}
                    row={row}
                    objUser={objUser}
                  />
                ),
              },
            ]
          : []),
      ];
  const columns = useMemo(() => objColumns, [table, takeTheAssessment]);

  const tableInstance = useTable(
    {
      columns,
      data,
      setPageSize,
      initialState: { pageIndex },
      manualPagination: true,
      pageCount: Math.ceil(totalCount / pageSize),
      globalFilter,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    setGlobalFilter: setTableGlobalFilter,
    setPageSize: setTablePageSize,
    state: {
      pageIndex: statePageIndex,
      pageSize: statePageSize,
      globalFilter: stateGlobalFilter,
    },
    state: { sortBy },
  } = tableInstance;

  useEffect(() => {
    setTablePageSize(pageSize);
    setData(
      table.rows.slice(
        statePageIndex * pageSize,
        (statePageIndex + 1) * pageSize
      )
    );
  }, [table.rows, pageSize, statePageIndex, setTablePageSize]);

  useEffect(() => {
    if (entriesPerPage && Array.isArray(entriesPerPage.entries)) {
      setEntries(entriesPerPage.entries.map((el) => el.toString()));
    }
  }, [entriesPerPage]);

  useEffect(() => {
    setTableGlobalFilter(globalFilter);
  }, [globalFilter, setTableGlobalFilter]);

  useEffect(() => {
    if (entriesPerPage && Array.isArray(entriesPerPage.entries)) {
      setEntries(entriesPerPage.entries.map((el) => el.toString()));
    }
  }, [entriesPerPage]);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || "");
  }, 100);

  const handleChange = (event, value) => {
    setPage(value);
    loadMoreItems(listId ? listId : pageSize, value);
  };

  const setEntriesPerPage = (value) => setPageSize(value);

  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asc";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };
  const entriesStart = statePageIndex * statePageSize + 1;
  const entriesEnd = Math.min((statePageIndex + 1) * statePageSize, totalCount);

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setFindingsToAdd([...findingsToAdd, row.original.id]);
    } else {
      setFindingsToAdd(
        findingsToAdd.filter((findingId) => findingId !== row.original.id)
      );
    }
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={globalFilter}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {!listId && (
                <DataTableHeadCell padding="checkbox" width="12%">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      indeterminate={
                        findingsToAdd.length > 0 &&
                        findingsToAdd.length < rows.length
                      }
                      checked={findingsToAdd.length === rows.length}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setFindingsToAdd(rows.map((row) => row.original.id));
                        } else {
                          setFindingsToAdd([]);
                        }
                      }}
                      inputProps={{ "aria-label": "select all contacts" }}
                    />
                  </div>
                </DataTableHeadCell>
              )}

              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  key={column.id}
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <Fragment key={key}>
                <TableRow {...row.getRowProps()}>
                  {!listId && (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                    >
                      <Checkbox
                        checked={findingsToAdd.includes(row.original.id)}
                        onChange={(event) => handleSelectRow(event, row)}
                      />
                    </DataTableBodyCell>
                  )}
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      key={cell.column.id}
                      noBorder={noEndBorder && data.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {entriesStart} to {entriesEnd} of {totalCount} entries
            </MDTypography>
          </MDBox>
        )}
        <MDBox mb={{ xs: 12, sm: 0 }}>
          <Pagination
            count={Math.ceil(totalCount / pageSize)}
            page={statePageIndex + 1}
            onChange={handleChange}
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          />
        </MDBox>
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of RiskRegisterTable
RiskRegisterTable.defaultProps = {
  entriesPerPage: { defaultValue: 25, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

export default RiskRegisterTable;
