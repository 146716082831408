const saveSelection = (document) => {
  const selection = document.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    // This range selects everything from the start of the document to the start of the selection.
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(document.body);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);
    const start = preSelectionRange.toString().length;
    return {
      start,
      end: start + range.toString().length,
    };
  }
  return null;
};

const restoreSelection = (document, savedSel) => {
  if (savedSel) {
    let charIndex = 0,
      range = document.createRange();
    range.setStart(document.body, 0);
    range.collapse(true);
    let nodeStack = [document.body],
      node,
      foundStart = false,
      stop = false;

    // Does a depth-first traversal of the DOM to find the text node that contains the selection.
    while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType == 3) {
        const nextCharIndex = charIndex + node.length;
        if (
          !foundStart &&
          savedSel.start >= charIndex &&
          savedSel.start <= nextCharIndex
        ) {
          range.setStart(node, savedSel.start - charIndex);
          foundStart = true;
        }
        if (
          foundStart &&
          savedSel.end >= charIndex &&
          savedSel.end <= nextCharIndex
        ) {
          range.setEnd(node, savedSel.end - charIndex);
          stop = true;
        }
        charIndex = nextCharIndex;
      } else {
        let i = node.childNodes.length;
        while (i--) {
          nodeStack.push(node.childNodes[i]);
        }
      }
    }
    const sel = document.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }
};

export { saveSelection, restoreSelection };
