import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { ErrorMessage, Field, validateYupSchema } from "formik";
import MDInput from "components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { TagsInput } from "react-tag-input-component";
import MDButton from "components/MDButton";
import "./EditPolicyFormFormikWrapper.css";
import { useMemo, useState } from "react";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import moment from "moment";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { AccessTime } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import { useMaterialUIController } from "context";

const EditPolicyFormFormikWrapper = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    lnkedPolicies,
    setLnkedPolicies,
    objPolicy,
  } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // const [lnkedPolicies, setLnkedPolicies] = useState(
  //   values.linkedPolicies.map((policy) => {
  //     return policy.linked_policy_id;
  //   })
  // );
  // useEffect(() => {
  //   props.templates.push({
  //     id: null,
  //     name: "No template",
  //     document: "",
  //   });
  // }, []);

  const RedAsterisk = styled("span")({
    color: "red",
  });

  function handleChange(frameworkId) {
    let newValues = values.riskManagementFrameworks.map((framework) => {
      return framework.id === frameworkId
        ? { ...framework, selected: !framework.selected }
        : framework;
    });
    setFieldValue("riskManagementFrameworks", newValues);
  }

  function handleIncludeInKnowledgebaseChange(value) {
    setFieldValue("includeInKnowledgebase", value.target.checked);
  }

  const handlelinkedPoliciesChange = (event) => {
    const {
      target: { value },
    } = event;
    setLnkedPolicies(typeof value === "string" ? value.split(",") : value);
  };

  const displayTitlesOfSelectedPolicies = (selected) => {
    // values.publishedPolicies.find((item)=>item.) selected.join(", ")
    /**
     * selected is an array of policy ids
     * We want to pick the corresponded titles from the appropriate  array
     */
    const selectedPoliciesToLink = selected.map((id) => {
      const plcy = values.publishedPolicies.find((item) => item.id === id);
      return plcy !== undefined ? plcy.title : false;
    });

    return selectedPoliciesToLink.join(", ");
  };

  const baseTimeReminder = useMemo(() => {
    if (!values.timeReminder) return moment();

    const timeReminder = moment(values.timeReminder);
    switch (values.timeReminderPeriod) {
      case "quarterly":
        return timeReminder.subtract(3, "months");
      case "semiannually":
        return timeReminder.subtract(6, "months");
      case "annually":
        return timeReminder.subtract(1, "years");
      default:
        return timeReminder;
    }
  }, [values.timeReminder, values.timeReminderPeriod]);

  const timeReminderOptions = [
    { id: "", label: "", timeReminder: null },
    {
      id: "quarterly",
      label: `Quarterly (Next Review: ${baseTimeReminder
        .clone()
        .add(3, "months")
        .format("LL")})`,
      timeReminder: baseTimeReminder
        .clone()
        .add(3, "months")
        .format("YYYY-MM-DD"),
    },
    {
      id: "semiannually",
      label: `Semi-Annually (Next Review: ${baseTimeReminder
        .clone()
        .add(6, "months")
        .format("LL")})`,
      timeReminder: baseTimeReminder
        .clone()
        .add(6, "months")
        .format("YYYY-MM-DD"),
    },
    {
      id: "annually",
      label: `Annually (Next Review: ${baseTimeReminder
        .clone()
        .add(1, "years")
        .format("LL")})`,
      timeReminder: baseTimeReminder
        .clone()
        .add(1, "years")
        .format("YYYY-MM-DD"),
    },
  ];

  // useEffect(() => {
  //   if (values.numberOfDaysBeforeToSendReminder < 0) {
  //     setValue(0);
  //   }
  // }, [values.numberOfDaysBeforeToSendReminder]);

  return (
    <MDBox my={3} mb={0} textAlign="left">
      <MDBox px={5} mb={0} textAlign="left" mx="auto" mt={4}>
        <Grid container justifyContent="space-between" columnSpacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            pt={0}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <MDBox mb={0}>
              <MDTypography variant="h5" fontWeight="bold">
                Edit{" "}
                {objPolicy.policy_type == "asset" ? "Asset List" : "Policy"}
              </MDTypography>
            </MDBox>
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                gap: 3,
              }}
            >
              {/* kb link */}
              <MDBox>
                {values.latestDocumentState === "Published" ? (
                  <>
                    {values.kbArticleData.kbAvailable ? (
                      <a
                        href={values.kbArticleData.kbArticleLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <MDButton
                          color="dark"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          <ArticleIcon></ArticleIcon>
                          View Knowledgebase Article
                        </MDButton>
                      </a>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <TextField
                    type="text"
                    label="KB Article Link"
                    value={"Not published"}
                    // disabled={true}
                    sx={{ pointerEvents: "none" }}
                  />
                )}
              </MDBox>
              {/* end kb link */}
              {/* checkbox */}
              <MDBox>
                <FormControlLabel
                  sx={{ marginRight: 0 }}
                  control={
                    <Checkbox
                      name="includeInKnowledgebase"
                      value={values.includeInKnowledgebase}
                      checked={values.includeInKnowledgebase === true}
                      onChange={handleIncludeInKnowledgebaseChange}
                      icon={
                        <CheckBoxOutlineBlankOutlinedIcon
                          style={{ borderColor: "#0b2e4a" }}
                        />
                      }
                    />
                  }
                  label="Include Published Policy in Knowledgebase"
                />
              </MDBox>
              {/* end checkbox */}
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={0} pt={2} px={5} pb={1}>
        <Grid container justifyContent="space-between" columnSpacing={8}>
          {/* <Grid item xs={12} lg={12}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                  <Checkbox
                    name="includeInKnowledgebase"
                    value={values.includeInKnowledgebase}
                    checked={values.includeInKnowledgebase === true}
                    onChange={handleIncludeInKnowledgebaseChange}
                    icon={
                      <CheckBoxOutlineBlankOutlinedIcon
                        style={{ borderColor: "#0b2e4a" }}
                      />
                    }
                  />
                }
                label="Include Published Policy in Knowledgebase"
              />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} pt={0}>
            <FormField
              type="text"
              label={
                <>
                  Title <RedAsterisk>*</RedAsterisk>
                </>
              }
              name="title"
              id="title"
              value={values.title}
              placeholder={""}
              error={errors.title && touched.title}
              success={values.title.length > 0 && !errors.title}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox mb={1.5}>
              <Autocomplete
                disableClearable
                value={values.category}
                options={props.categories}
                getOptionLabel={(option) => (option ? option.display_name : "")}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={
                      <>
                        Category <RedAsterisk>*</RedAsterisk>
                      </>
                    }
                    fullWidth
                  />
                )}
                onChange={(event, value) => {
                  if (value === null) {
                    setFieldValue("category", "");
                  } else {
                    setFieldValue("category", value);
                  }
                }}
              />
              <MDBox mt={0.75}>
                <MDTypography
                  component="div"
                  variant="caption"
                  color="error"
                  fontWeight="regular"
                >
                  <ErrorMessage
                    name="category"
                    render={(msg) => {
                      return <div>Field is required.</div>;
                    }}
                  />
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormField
              type="text"
              label="Reference Material URL"
              name="referenceMaterialUrl"
              value={values.referenceMaterialUrl}
              error={
                errors.referenceMaterialUrl && touched.referenceMaterialUrl
              }
              success={
                values.referenceMaterialUrl.length > 0 &&
                !errors.referenceMaterialUrl
              }
            />
          </Grid>
          {/* owner */}
          <Grid item xs={12} lg={6}>
            {/* owner */}
            <MDBox mb={1.5}>
              <Autocomplete
                disableClearable
                value={values.owner}
                options={props.contacts}
                getOptionLabel={(option) =>
                  option
                    ? option.first_name +
                      " " +
                      option.last_name +
                      " (" +
                      option.email +
                      ")"
                    : ""
                }
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={
                      <>
                        Owner <RedAsterisk>*</RedAsterisk>
                      </>
                    }
                    fullWidth
                  />
                )}
                onChange={(event, value) => {
                  if (value === null) {
                    setFieldValue("owner", "");
                  } else {
                    setFieldValue("owner", value);
                  }
                }}
              />
              <MDBox mt={0.75}>
                <MDTypography
                  component="div"
                  variant="caption"
                  color="error"
                  fontWeight="regular"
                >
                  <ErrorMessage
                    name="owner"
                    render={(msg) => {
                      return <div>Field is required.</div>;
                    }}
                  />
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MDBox mb={1.5}>
              <label className="tags-label" htmlFor="policyTags">
                {objPolicy.policy_type == "asset" ? "Asset List" : "Policy"}{" "}
                Tags
              </label>
              <TagsInput
                value={values.policyTags}
                onChange={(value) => {
                  if (value === null) {
                    setFieldValue("policyTags", []);
                  } else {
                    setFieldValue("policyTags", value);
                  }
                }}
                name="policyTags"
                placeHolder=""
                className="tags-input-container"
              />
              <MDBox mt={0.75}>
                <MDTypography
                  component="div"
                  variant="caption"
                  color="error"
                  fontWeight="regular"
                >
                  <ErrorMessage
                    name="policyTags"
                    render={(msg) => {
                      return <div>Field is required.</div>;
                    }}
                  />
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} lg={6} mb={2} sx={{ display: "none" }}>
            <FormField
              type="text"
              label={
                objPolicy.policy_type == "asset"
                  ? "Asset List Creator"
                  : "Policy Creator"
              }
              name="policyCreator"
              value={values.policyCreator}
              error={errors.policyCreator && touched.policyCreator}
              success={values.policyCreator.length > 0 && !errors.policyCreator}
              disabled={true}
            />
          </Grid> */}

          {/* <Grid item xs={12} lg={6} mb={2}>
            <FormField
              type="text"
              label="Compliance Risk Score *(Input value 1 - 5)"
              name="complianceRiskScore"
              value={values.complianceRiskScore}
              error={errors.complianceRiskScore && touched.complianceRiskScore}
              success={
                values.complianceRiskScore.length > 0 &&
                !errors.complianceRiskScore
              }
              onKeyPress={(event) => {
                if (!/[1-5]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Grid> */}

          <Grid
            className="no-pt-element"
            container
            // mt={3}
            item
            xs={12}
            columnSpacing={8}
            pt={0}
          >
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Intended Audience"
                sx={{ display: "none" }}
                name="intendedAudience"
                value={values.intendedAudience}
                error={errors.intendedAudience && touched.intendedAudience}
                success={
                  values.intendedAudience.length > 0 && !errors.intendedAudience
                }
              />
            </Grid>

            {/* Related document */}
            <Grid item xs={12} lg={12} mb={2}>
              {/* <FormField
                type="text"
                label="Related Document"
                name="relatedDocument"
                value={values.relatedDocument}
                error={errors.relatedDocument && touched.relatedDocument}
                success={
                  values.relatedDocument.length > 0 && !errors.relatedDocument
                }
              /> */}
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="Related Documents">
                  Related Documents
                </InputLabel>
                <Select
                  labelId="Related Documents"
                  label="Related Documents"
                  id="Related Documents"
                  multiple
                  name="linkedPolicies"
                  value={lnkedPolicies}
                  renderValue={(selected) =>
                    displayTitlesOfSelectedPolicies(selected)
                  }
                  sx={{ height: "43px" }}
                  onChange={handlelinkedPoliciesChange}
                >
                  {values.publishedPolicies.map((policy) => {
                    return (
                      <MenuItem key={policy.id} value={policy.id}>
                        <Checkbox
                          checked={lnkedPolicies.indexOf(policy.id) > -1}
                        />
                        {policy.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={12}>
              <div>
                <FormField
                  type="text"
                  label="Knowledge Base"
                  name="knowledgeBase"
                  value={values.knowledgeBase}
                  error={errors.knowledgeBase && touched.knowledgeBase}
                  success={
                    values.knowledgeBase.length > 0 && !errors.knowledgeBase
                  }
                  sx={{ display: "none" }}
                />
              </div>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormLabel sx={{ fontSize: "0.75em" }}>
                Policy Packs (RMFs) <RedAsterisk>*</RedAsterisk>
              </FormLabel>

              {values.riskManagementFrameworks.map((framework) => (
                <div
                  key={framework?.id}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Switch
                    key={framework?.id}
                    checked={framework.selected == 1 ? true : false}
                    name={
                      framework.rmf ? framework.rmf.name.replace(/\s/g, "") : ""
                    }
                    onChange={() => handleChange(framework.id)}
                  />
                  <span style={{ marginLeft: 6, fontSize: 13, fontWeight: 800, color: darkMode ? "white" : "black" }}>
                    {framework.rmf ? framework.rmf.name : ""}
                  </span>
                </div>
              ))}
            </Grid>

            <Grid item xs={12} lg={6} mb={2}>
              <FormField
                sx={{ marginBottom: "0.4rem" }}
                pt={0}
                type="text"
                label={
                  <>
                    Short Description <RedAsterisk>*</RedAsterisk>
                  </>
                }
                name="shortDescription"
                value={values.shortDescription}
                placeholder={""}
                error={errors.shortDescription && touched.shortDescription}
                success={
                  values.shortDescription > 0 && !errors.shortDescription
                }
              />

              <FormField
                pt={0}
                sx={{ marginBottom: "0.8rem" }}
                type="text"
                label="Notes"
                name="notes"
                value={values.notes}
                placeholder={""}
                error={errors.notes && touched.notes}
                success={values.notes > 0 && !errors.notes}
              />

              <Card
                sx={{
                  padding: "2rem",
                  marginTop: "1rem",
                  backgroundColor: darkMode ? "#f0f0f091" : "#f0f0f0",
                }}
              >
                <MDTypography mb={2} variant="h6" color="info" p={0}>
                  <AccessTime sx={{ transform: "translateY(2px)" }} />{" "}
                  {objPolicy.policy_type == "asset" ? "Asset List" : "Policy"}{" "}
                  Review Reminder Settings
                </MDTypography>
                <MDBox mb={2}>
                  <Autocomplete
                    disableClearable
                    value={values.timeReminderPeriod}
                    options={timeReminderOptions}
                    getOptionLabel={(option) =>
                      option.label ??
                      timeReminderOptions.find((value) => value.id === option)
                        .label
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.id === value
                    }
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label={
                          <>
                            Document Compliance Review Date{" "}
                            <RedAsterisk>*</RedAsterisk>
                          </>
                        }
                        fullWidth
                      />
                    )}
                    onChange={(event, value) => {
                      if (value === null) {
                        setFieldValue("timeReminder", "");
                        setFieldValue("timeReminderPeriod", "");
                      } else {
                        setFieldValue("timeReminder", value.timeReminder);
                        setFieldValue("timeReminderPeriod", value.id);
                      }
                    }}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormField
                    type="number"
                    label="Number of days before to send notification"
                    name="numberOfDaysBeforeToSendReminder"
                    value={values.numberOfDaysBeforeToSendReminder}
                    onChange={(event, value) => {
                      if (event.target.value < 0) {
                        setFieldValue("numberOfDaysBeforeToSendReminder", 0);
                      } else {
                        setFieldValue(
                          "numberOfDaysBeforeToSendReminder",
                          event.target.value
                        );
                      }
                    }}
                    // error={
                    //   errors.numberOfDaysBeforeToSendReminder &&
                    //   touched.numberOfDaysBeforeToSendReminder
                    // }
                    // success={
                    //   values.numberOfDaysBeforeToSendReminder.length > 0 &&
                    //   !errors.numberOfDaysBeforeToSendReminder
                    // }
                  />
                </MDBox>
                <MDBox mb={2}>
                  <label className="tags-label" htmlFor="reminderEmails">
                    Emails to send notification to (press "enter" to add
                    multiple) <RedAsterisk>*</RedAsterisk>
                  </label>
                  <TagsInput
                    value={values.reminderEmails}
                    onChange={(value) => {
                      if (value === null) {
                        setFieldValue("reminderEmails", []);
                      } else {
                        setFieldValue("reminderEmails", value);
                      }
                    }}
                    name="reminderEmails"
                    placeHolder=""
                    className="tags-input-container"
                  />
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      <ErrorMessage
                        name="reminderEmails"
                        render={(msg) => {
                          return <div>Field is required.</div>;
                        }}
                      />
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default EditPolicyFormFormikWrapper;
