// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const SaveConnectWiseApiKeysFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue } = props;
  console.log(values);
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h4"
          fontWeight="bold"
        >
          Add Your ConnectWise API Keys
        </MDTypography>
        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Connectwise URL"
                name="url"
                value={values.url}
                placeholder={"Connectwise URL"}
                error={errors.url && touched.url}
                success={values.url.length > 0 && !errors.url}
              />
            </Grid>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Public key"
                name="public_key"
                value={values.public_key}
                placeholder={"Public key"}
                error={errors.public_key && touched.public_key}
                success={values.public_key.length > 0 && !errors.public_key}
              />
            </Grid>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="password"
                label="Enter Your Private key"
                name="private_key"
                value={values.private_key}
                placeholder={"Private key"}
                error={errors.private_key && touched.private_key}
                success={values.private_key.length > 0 && !errors.private_key}
              />
            </Grid>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Company id"
                name="company_id"
                value={values.company_id}
                placeholder={"Company id"}
                error={errors.company_id && touched.company_id}
                success={values.company_id.length > 0 && !errors.company_id}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveConnectWiseApiKeysFormFormikWrapper;
