import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import { Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const SaveApiKeysFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue } = props;
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Add Your ConnectSecure v4 API Keys
        </MDTypography>

        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <MDBox mb={2}>
              <Alert icon={<InfoIcon fontSize="inherit" />} severity="success">
                <a
                  target="_blank"
                  href="https://cybercns.atlassian.net/wiki/spaces/CVB/pages/2143977520/V4+Getting+Started+In+App+Info"
                >
                  How to get your POD and Tenant Name
                </a>{" "}
              </Alert>
            </MDBox>

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your POD"
                name="url"
                value={values.url}
                placeholder={"POD###"}
                error={errors.url && touched.url}
                success={values.url.length > 0 && !errors.url}
              />
            </Grid>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Tenant Name"
                name="customer_id"
                value={values.customer_id}
                placeholder={"Tenant Name"}
                error={errors.customer_id && touched.customer}
                success={values.customer_id.length > 0 && !errors.customer_id}
              />
            </Grid>

            <MDBox mb={3} mt={3}>
              <Alert icon={<InfoIcon fontSize="inherit" />} severity="success">
                How to get your client ID/secret:
                <p>
                  1. Login to your Connectsecure portal and use the link{" "}
                  <a href="https://portal.myconnectsecure.com/settings/view/users">
                    click here
                  </a>
                </p>
                <p>2. Click on "Action" {">"} Api Key</p>
              </Alert>
            </MDBox>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Client ID"
                name="client_id"
                value={values.client_id}
                placeholder={"Client ID"}
                error={errors.client_id && touched.client_id}
                success={values.client_id.length > 0 && !errors.client_id}
              />
            </Grid>

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="password"
                label="Enter Your Client Secret"
                name="client_secrete"
                value={values.client_secrete}
                placeholder={"Client Secret"}
                error={errors.client_secrete && touched.client_secrete}
                success={
                  values.client_secrete.length > 0 && !errors.client_secrete
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveApiKeysFormFormikWrapper;
